import { Icon, Button } from "@redsales/ds"

import { Link } from "gatsby-link"
import If from "components/If"
import Fields from "components/Fields"
import AuthError from "components/Auth/Error"
import AuthFeedback from "components/Auth/Feedback"

import useFormForgotPassword from "./hooks"
import { values, validation } from "./form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm as useFormHook } from "react-hook-form"

import "./ForgotPassword.scss"

const ForgotPassword = () => {
	const { sended, onSubmit, onConcluded, oauthErrors, isRequestingAPI } =
		useFormForgotPassword()

	const {
		control,
		handleSubmit,
		formState: { isValid },
	} = useFormHook({
		defaultValues: values,
		mode: "onChange",
		...(validation && { resolver: yupResolver(validation) }),
	})

	return (
		<div className="forgot-password">
			<If condition={!sended}>
				<Icon name="key" className="forgot-password__top-icon" />

				<h1 className="logged-out__title forgot-password__title">
					Esqueci minha senha
				</h1>

				<If condition={oauthErrors.message}>
					<AuthError message={oauthErrors.message} data-test="error" />
				</If>

				<form
					className="forgot-password-form"
					onSubmit={handleSubmit(onSubmit)}
				>
					<p
						className="forgot-password-form__description logged-out__description"
						data-test="description"
					>
						Digite o seu email de acesso no campo a seguir e clique no botão
						"Enviar link" para receber um link de redefinição de senha.
					</p>
					<Fields.Input.Text
						name="email"
						control={control}
						placeholder="Digite seu email"
						className="forgot-password-form__input"
					/>

					<Button
						size="big"
						type="submit"
						disabled={!isValid || isRequestingAPI}
						isLoading={isRequestingAPI}
						className="forgot-password-form__submit"
					>
						Enviar link
					</Button>
				</form>

				<Link
					to={ROUTES.login.path}
					className="forgot-password-form__back-login"
				>
					<Icon name="arrow-left" />
					Voltar para tela de acesso
				</Link>
			</If>

			<If condition={sended}>
				<AuthFeedback
					icon="mail-sent"
					title="Verifique seu email"
					description={`
							Se o endereço de email informado estiver cadastrado em nosso sistema, enviaremos um link de redefinição de senha. <br/><br/> Verifique sua caixa de entrada e, caso não encontre o email, confira a pasta de spam ou tente novamente em alguns minutos.`}
					button="Voltar para a tela de acesso"
					submitComponent="link"
					onClick={onConcluded}
					data-test="feedback"
				/>
			</If>
		</div>
	)
}

export default ForgotPassword
