import { navigate } from "gatsby"
import { useState } from "react"
import services from "services"

const useFormForgotPassword = () => {
  const [sended, setSended] = useState()
  const [oauthErrors, setOauthErrors] = useState({ message: null })
  const [isRequestingAPI, setIsRequestingAPI] = useState(false)

  const onSubmit = (formData) => {
    setIsRequestingAPI(true)
    services.auth.forgotPassword(formData)
      .then(handleForgotPassword)
      .then(() => setIsRequestingAPI(false))
  }

  const onConcluded = () => navigate(ROUTES.login.path)

  const handleForgotPassword = ({ error, message }) => {
    if (error) {
      setOauthErrors({ message })
    } else {
      setOauthErrors({ message: null })
      setSended(true)
    }
  }

  return {
    sended,
    onSubmit,
    onConcluded,
    oauthErrors,
    isRequestingAPI
  }
}

export default useFormForgotPassword
